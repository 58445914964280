import { RefObject, useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue, useSetAtom } from "jotai";
import { useRouter } from "next/router";

import { IS_ON_BROWSER } from "@sellernote/_shared/src/constants";
import { SCHEDULE_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/SCHEDULE_QUERY";
import {
  OrderOption,
  SortOption,
} from "@sellernote/_shared/src/types/forwarding/schedule";
import { checkIsNull } from "@sellernote/_shared/src/utils/common/validation";
import Button from "@sellernote/_sds-v2/src/components/button/Button";
import { InputSearchWithOptionsHandlerList } from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";
import useScheduleSearchBarQueryStates from "@sellernote/shipda-kr/src/hooks/forwarding/useScheduleSearchBarQueryStates";
import { COMMON_MAIN_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/main";
import { FORWARDING_COMMON_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/forwarding/common";

import { useSearch } from "../SearchContext";

export default function Search({
  polOptionHandlerListRef,
  podOptionHandlerListRef,
  isMobile = false,
}: {
  polOptionHandlerListRef: RefObject<InputSearchWithOptionsHandlerList>;
  podOptionHandlerListRef: RefObject<InputSearchWithOptionsHandlerList>;
  isMobile?: boolean;
}) {
  // TODO: A/B 테스트가 끝나면 삭제
  const searchRef = useRef<HTMLDivElement>(null);
  const setIsRegistrationButtonVisible = useSetAtom(
    COMMON_MAIN_ATOMS.IS_REGISTRATION_BUTTON_VISIBLE_ATOM
  );
  useEffect(() => {
    if (!IS_ON_BROWSER) {
      return;
    }

    const isBTestType =
      window.localStorage.getItem(
        "LOCAL_STORAGE_KEY_FOR_SHIPDA_HERO_A_B_TEST_KEY"
      ) !== "B";
    if (!(isBTestType && isMobile)) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsRegistrationButtonVisible(!entry.isIntersecting);
      },
      // 수출 배너(54px) + 헤더(48px)
      { rootMargin: "-102px 0px 0px 0px" }
    );

    const search = searchRef.current;

    if (search) {
      observer.observe(search);
    }

    return () => {
      if (search) {
        observer.unobserve(search);
      }
    };
  }, [isMobile, setIsRegistrationButtonVisible]);

  const perPage = useAtomValue(
    FORWARDING_COMMON_ATOMS.FORWARDING_TABLE_PER_PAGE
  );

  const [
    { freightType, polId, polPortGroupId, podId, podPortGroupId, etd, eta },
  ] = useSearch();

  const [
    {
      minTransitDay,
      maxTransitDay,
      filterPolIds,
      filterPodIds,
      transit,
      linerIdList,
      sort,
      order,
      page,
    },
    setScheduleSearchBarQueryStates,
  ] = useScheduleSearchBarQueryStates();

  const { push, pathname } = useRouter();

  const queryClient = useQueryClient();

  const handleSearch = async () => {
    if (!polId) {
      polOptionHandlerListRef.current?.focusInput();
      return;
    }

    if (!podId) {
      podOptionHandlerListRef.current?.focusInput();
      return;
    }

    if (!etd) {
      return;
    }

    if (pathname === "/") {
      push(
        `/forwarding/schedule?${new URLSearchParams({
          freightType,
          polId: polId.toString(),
          ...(polPortGroupId && { polPortGroupId: polPortGroupId.toString() }),
          ...(podPortGroupId && {
            podPortGroupId: podPortGroupId.toString(),
          }),
          podId: podId.toString(),
          etd,
          ...(eta && { eta }),

          page: "0",

          isSearchBar: "true",

          ...(isMobile && { needsAutoScroll: "true" }),
        }).toString()}`
      );
      return;
    }

    await setScheduleSearchBarQueryStates({
      freightType,
      polId,
      polPortGroupId,
      podId,
      podPortGroupId,
      etd,
      eta,

      page: 0,

      minTransitDay: null,
      maxTransitDay: null,
      filterPodIds: null,
      filterPolIds: null,
      transit: null,
      linerIdList: null,

      sort: SortOption.TransitTimeInDays,
      order: OrderOption.Asc,

      isSearchBar: true,

      needsAutoScroll: true,
    });

    // 초기 조회 조건과 동일한 조건으로 '조회' 버튼을 누른 경우 강제로 fetch 해준다.
    const isInitial =
      checkIsNull(minTransitDay) &&
      checkIsNull(maxTransitDay) &&
      checkIsNull(filterPolIds) &&
      checkIsNull(filterPodIds) &&
      checkIsNull(transit) &&
      checkIsNull(linerIdList) &&
      page === 0 &&
      sort === SortOption.TransitTimeInDays &&
      order === OrderOption.Asc;
    if (isInitial) {
      queryClient.invalidateQueries(
        SCHEDULE_QUERY_KEY_GEN.getScheduleList({
          freightType,
          polId,
          podId,
          etd,
          ...(eta && { eta }),

          perPage,
          page: 0,

          sort: SortOption.TransitTimeInDays,
          order: OrderOption.Asc,
        })
      );
    }
  };

  return (
    <div ref={searchRef}>
      <Button
        {...(isMobile && { width: "100%" })}
        className="search-submit custom-button-height"
        size="large"
        theme={isMobile ? "primary" : "secondary"}
        label="조회"
        borderType="filled"
        handleClick={handleSearch}
      />
    </div>
  );
}
