import { RefObject, useMemo } from "react";

import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import { InputSearchWithOptionsHandlerList } from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";
import PlaneIcon from "@sellernote/_sds-v2/src/components/svgIcons/PlaneIcon";
import Ship3Icon from "@sellernote/_sds-v2/src/components/svgIcons/Ship3Icon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { getOptionList, getPortType, getRecommendationList } from "../utils";

import { useSearch } from "../SearchContext";
import InputSearchForSchedule, {
  InputSearchForScheduleOption,
} from "./InputSearchForSchedule";

export default function Pol({
  polOptionHandlerListRef,
  isMobile = false,
}: {
  polOptionHandlerListRef: RefObject<InputSearchWithOptionsHandlerList>;
  isMobile?: boolean;
}) {
  const [{ freightType, polId, podId }, setSearch] = useSearch();

  const { data: portList } = COMMON_QUERY.useGetUserPortList({
    portType: getPortType(freightType),
  });

  const polOptionList = useMemo(() => getOptionList(portList), [portList]);

  const polRecommendationList = useMemo(
    () =>
      getRecommendationList({
        portList,
        freightType,
        locationType: "pol",
        portCountryOfAnotherLocationType: portList?.find(
          (port) => port.id === podId
        )?.country,
      }),
    [freightType, podId, portList]
  );

  const selectedPort = useMemo(() => {
    return portList?.find((port) => port.id === polId);
  }, [polId, portList]);

  const leftIconInfo: InputSearchForScheduleOption<number>["iconInfo"] =
    useMemo(() => {
      if (!selectedPort) return;

      return selectedPort.type === "sea"
        ? { Icon: Ship3Icon, color: COLOR.bk_80 }
        : { Icon: PlaneIcon, color: COLOR.bk_80 };
    }, [selectedPort]);

  const handlePolSelect = (selectedPolId: number | null) => {
    const selectedPort = portList?.find((port) => port.id === selectedPolId);

    setSearch({
      polId: selectedPolId,
      polPortGroupId: selectedPort?.portGroupId,
    });
  };

  const handlePolReset = () => {
    setSearch({ polId: null, polPortGroupId: null });
  };

  return (
    <InputSearchForSchedule<number | null>
      className="custom-label-color"
      {...(!isMobile && { width: 296 })}
      labelInfo={{
        label: (
          <>
            출발지<span className="required">*</span>
          </>
        ),
        position: "top",
      }}
      placeholder="항구/공항을 입력해주세요."
      searchSourceList={polOptionList}
      selectedOptionValue={polId}
      leftIconInfo={leftIconInfo}
      onSelect={handlePolSelect}
      onReset={handlePolReset}
      recommendationList={polRecommendationList}
      inputSearchForScheduleHandlerList={polOptionHandlerListRef}
    />
  );
}
